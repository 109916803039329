import loadable from '@loadable/component';

import React from 'react';

import { useSelector } from 'react-redux';

import acl from 'tools/acl';

import Loading from 'components/Loading';
import Redirect from 'components/Routes/Redirect';

const NoMatch404 = loadable(() => import('pages/NoMatch404'), { fallback: <Loading fullPage /> });

type Props = {
  location: Object,
  history: Object,
};

const ProfileMe = ({ location, history }: Props) => {
  const user = useSelector(state => state.user.privateProfile);
  if (acl.isConnected(user)) {
    return <Redirect to={`/profile/${user.username}#artworks`} />;
  }
  return <NoMatch404 location={location} history={history} />;
};

export default ProfileMe;
